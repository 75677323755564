import React from 'react';
import { PageOsteopathie } from '@src/ui';
import { usePageList } from '@src/hooks/page-list';

const Page: React.FC = () => {
  const pages = usePageList();
  const currentPageData = pages.find(p => p.id === 'osteopathie');
  return <PageOsteopathie image={currentPageData?.image} />;
};

export default Page;
